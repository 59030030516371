<template>
	<div v-show="customerServiceState" id="right-bottom-block">
		<div class="btn-block" @click="closeCustomerService">
			<button class="btn-close btn-close-white btn-close-line"></button>
		</div>
		<!-- //TODO 官方微信 -->
		<a
			class="link-block"
			href="https://page.line.me/yhz1055p?openQrModal=true"
			target="_blank"
		>
			<img
				:src="require('@/assets/images/common/line_official.png')"
				alt="官方微信"
			/>
		</a>
	</div>
</template>
<script setup>
import { getCurrentInstance, ref, watch } from "vue";
import { useRoute } from "vue-router";
const globalProperties =
	getCurrentInstance().appContext.config.globalProperties;
const storage = globalProperties.$storage;
const route = useRoute();
const customerServiceState = ref(false);
const closeCustomerService = () => {
	customerServiceState.value = false;
	storage.setStorageSync("hideCustomerService", true, 3600000);
};
if (!globalProperties.isServer) {
	const showCustomerService = () => {
		if (
			storage.getStorageSync("hideCustomerService") ||
			route.meta.hideCustomerService
		) {
			customerServiceState.value = false;
		} else {
			customerServiceState.value = true;
		}
	};
	watch(
		() => route.path,
		(newPath, oldPath) => {
			showCustomerService();
		}
	);
	showCustomerService();
}
</script>
<style lang="scss" scoped>
#right-bottom-block {
	position: fixed;
	z-index: 1021;
	right: 10px;
	bottom: 55px;
	max-width: 100px;
	width: 100%;
	text-align: right;
	@media all and (min-width: 768px) {
		max-width: 125px;
		right: 20px;
		bottom: 20px;
	}
	.btn-block {
		cursor: pointer;
		position: absolute;
		z-index: 999;
		top: -16px;
		right: 0;
		border: 1px solid #c1c1c1;
		background-color: #c1c1c1;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		padding: 0.5rem;

		@media all and (min-width: 768px) {
			width: 24px;
			height: 24px;
		}
		.btn-close {
			font-size: 9px;
			position: absolute;
			top: 50%;
			left: 50%;
			opacity: 1;
			transform: translate(-50%, -50%);
			&:focus {
				box-shadow: none;
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	.link-block {
		display: inline-block;
		img {
			display: block;
			max-width: 100%;
			width: 100%;
			transition: all 0.15s ease-in;
		}
		&:hover {
			img {
				-webkit-transform: scale(1.03, 1.03);
				-ms-transform: scale(1.03, 1.03);
				transform: scale(1.03, 1.03);
			}
		}
	}
}
</style>
